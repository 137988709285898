

























































import { Component, Vue } from 'vue-property-decorator';

import AlternativeIndex from '@/components/Question/AlternativeIndex/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { IRelateColumnsAlternative, IRelateColumnsAnswers } from '@/globalInterfaces/Questions';

import { optimizeImageLoadingInAlternative } from '@/share/Util/Text/index';

@Component({
  components: {
    AlternativeIndex,
    FeedbackUser,
  },
})
export default class ResolutionRelateColumns extends Vue {
  optimizeImage(text: string) {
    return optimizeImageLoadingInAlternative(text);
  }

  get shuffledAlternatives(): IRelateColumnsAlternative[] {
    return this.$store.getters.shuffledAlternativesRelateColumns;
  }

  get answers(): IRelateColumnsAnswers[] {
    const answersStore: IRelateColumnsAnswers[] = this.$store.getters.answersRelateColumns;

    if (!answersStore.length || !this.shuffledAlternatives.length) return [];

    return answersStore.map((answer: IRelateColumnsAnswers) => {
      const alternativeFound = this.shuffledAlternatives.find((alternative: IRelateColumnsAlternative) => alternative.ID === answer.optionID);

      return { ...answer, indexCorrect: alternativeFound?.index || null };
    });
  }
}
