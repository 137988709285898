





























import { Component, Prop, Vue } from 'vue-property-decorator';

import AlternativeIndex from '@/components/Question/AlternativeIndex/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { IOption } from '@/globalInterfaces/Questions';

import { FALSY, TRUTHY } from '@/constant/Question';

@Component({
  components: {
    AlternativeIndex,
    FeedbackUser,
  },
})
export default class ResolutionTruthyOrFalsy extends Vue {
  @Prop({ required: false }) answers!: Array<IOption>;

  setAnswer(option: any) {
    return option?.validated === 1 ? TRUTHY : FALSY;
  }
}
